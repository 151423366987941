import * as React from "react";
import {
  ReviewAlertFooterService,
  TrustPilotModal,
} from "@origin-digital/platform-personalisation";
import { KrakenAppContent, UnauthAppContent } from "../AppContent";
import { SuppressAndroidInstallPrompt } from "../SuppressAndroidInstallPrompt";
import { CrisisBanner } from "../Banner";

import { PrefetchContext, PrefetchedContent } from "../prefetch";
import Breadcrumbs, { getBreadcrumbsContent } from "../Breadcrumbs";
import { InAppMessagingService } from "../InAppMessagingService";
import RootWrapper from "../RootWrapper";

//TODO: Type this exactly to match what tal-core passes on.
export interface AppProps {
  title: string;
  actionBar?: Window["oetal"]["pageData"]["actionBar"];
  authenticated?: boolean;
  background?: "gray" | "white";
  channel?: string;
  children?: React.ReactNode;
  prefetchContent?: Record<string, PrefetchedContent>;
  noSeed?: boolean;
  platform?: string;
  presets?: string[];
  showHeader?: boolean | "unauth" | "scoped";
  showNativeAppAd?: boolean;
  enableCrisisBanner?: boolean;
}

const App = ({
  actionBar,
  channel,
  prefetchContent = {},
  platform,
  presets,
  showHeader = true,
  showNativeAppAd = true,
  enableCrisisBanner = true,
  title,
}: AppProps) => {
  const isKraken =
    platform === "kraken" || (presets && presets.includes("kraken"));
  const isNativeChannel = channel === "native";
  const breadcrumbsContent = getBreadcrumbsContent(prefetchContent);
  return (
    <RootWrapper>
      <PrefetchContext.Provider value={prefetchContent}>
        <TrustPilotModal />
        <ReviewAlertFooterService />
        <InAppMessagingService />
        {!showNativeAppAd && <SuppressAndroidInstallPrompt />}
        {enableCrisisBanner && !isNativeChannel && <CrisisBanner />}
        {isKraken ? (
          <KrakenAppContent
            title={title}
            showHeader={showHeader}
            actionBar={actionBar}
            enableCrisisBanner={enableCrisisBanner}
            isNative={isNativeChannel}
          />
        ) : (
          <UnauthAppContent
            actionBar={actionBar}
            showHeader={showHeader}
            title={title}
            enableCrisisBanner={enableCrisisBanner}
            isNative={isNativeChannel}
          />
        )}
        {breadcrumbsContent && <Breadcrumbs data={breadcrumbsContent} />}
      </PrefetchContext.Provider>
    </RootWrapper>
  );
};

export { App };
