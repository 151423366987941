import * as React from "react";

export const Logo = ({ id }: { id: string }) => {
  // To avoid having ids clash for linear gradient definition, it is passed in
  return (
    <svg width="56" height="64" viewBox="0 0 56 64" id={id}>
      <title>Origin Energy Logo</title>
      <path
        d="M12.21,44.61c-1.08,0-1.97.9-1.97,1.95s.89,1.97,1.97,1.97,1.98-.9,1.98-1.97-.89-1.95-1.98-1.95M12.21,50.31c-2.1,0-3.79-1.68-3.79-3.74s1.68-3.73,3.79-3.73,3.8,1.65,3.8,3.73-1.68,3.74-3.8,3.74M17.52,42.98h1.85v.53c.69-.51,1.37-.66,2.75-.66v1.77c-2.36,0-2.75.66-2.75,1.89v3.61h-1.85v-7.14h0ZM23.48,42.98h1.85v7.14h-1.85v-7.14ZM36.68,42.98h1.85v7.14h-1.85v-7.14ZM45.44,46.3c0-1.38-.74-1.68-1.47-1.68s-1.47.29-1.47,1.65v3.85h-1.85v-7.14h1.85v.53c.41-.3.9-.66,1.88-.66,1.56,0,2.91.92,2.91,3.15v4.12h-1.85v-3.82h0ZM32.81,52.48c-.11.97-.95,1.75-1.97,1.75-1.08,0-1.97-.9-1.97-1.97s.88-1.95,1.97-1.95c1.02,0,1.86.78,1.97,1.73.01.07.01.15.01.22,0,.08,0,.15-.01.22M28.88,46.57c0-1.05.88-1.95,1.97-1.95,1.02,0,1.86.78,1.97,1.73.01.07.01.15.01.22s0,.15-.01.22c-.11.97-.95,1.75-1.97,1.75-1.08,0-1.97-.9-1.97-1.97M34.65,46.57v-3.59h-1.82v.4s0,0-.01-.01c-.57-.34-1.24-.53-1.97-.53-2.1,0-3.79,1.65-3.79,3.73,0,1.14.52,2.16,1.33,2.85-.82.68-1.33,1.7-1.33,2.84,0,2.06,1.68,3.74,3.79,3.74s3.8-1.68,3.8-3.74c0-1.15-.51-2.16-1.33-2.84.82-.68,1.33-1.71,1.33-2.85M11.67,24.19c0,5.36,2.6,10.11,6.61,13.05,2.68-1.97,5.99-3.14,9.58-3.14s6.89,1.17,9.58,3.14c4.01-2.95,6.61-7.69,6.61-13.05,0-8.94-7.25-16.19-16.19-16.19-8.94,0-16.19,7.25-16.19,16.19M19.59,24.19c0-4.56,3.7-8.26,8.26-8.26s8.26,3.7,8.26,8.26-3.7,8.26-8.26,8.26-8.26-3.7-8.26-8.26"
        fill="#ff6124"
        strokeWidth="0"
      />
    </svg>
  );
};
