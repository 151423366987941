import { lighten } from "@mui/material";
import { IconClose, IconInfo, IconWarning } from "@origin-digital/ods-icons";
import React from "react";
import styled from "styled-components";
import { BREAK_POINTS } from "../../consts/style";

const ParentDiv = styled.div<{ type?: string }>`
  display: flex;
  font-family: "gordita", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.7em;
  background-color: ${(props) =>
    props.type === "warning"
      ? lighten(props.theme.palette.warning.main, 0.92)
      : lighten(props.theme.palette.info.main, 0.92)};
  color: ${(props) => props.theme.palette.grey[500]};
  & a {
    color: ${(props) =>
      props.type === "warning" ? "#7b0000" : "rgb(80, 80, 80)"};
  }
`;

const Container = styled.div`
  display: block;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media (min-width: ${BREAK_POINTS.MIN.LG}) {
    width: 1170px;
  }
`;

const WrapperDiv = styled.div`
  margin: 16px 12px;
  display: flex;
`;

const LeftDiv = styled.div`
  padding-right: 12px;
`;

const RightDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const TitleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NotificationHeader = styled.h4`
  text-transform: none;
  margin: 0;
  font-weight: 600;
  padding-right: 4px;
`;

const Paragraph = styled.span`
  margin: 0;
`;

export const CloseButton = styled.button`
  margin: 0;
  border: 0;
  font-size: 24px;
  padding: 0;
  background: transparent;
  align-self: flex-start;
  &:hover {
    cursor: pointer;
  }
`;

const SrOnlySpan = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const IconSpan = styled.span`
  display: flex;

  & > svg {
    padding: 4px;
  }
`;

export interface IBannerView {
  index: number;
  bannerId?: number;
  type?: string;
  heading: string;
  message: string;
  onCloseButtonClick: () => void;
  closeBtnDataId: string;
}

const CloseIconButton = ({
  closeBtnDataId,
  onCloseButtonClick,
}: Partial<IBannerView>) => (
  <CloseButton
    title="Close"
    data-id={closeBtnDataId}
    onClick={onCloseButtonClick}
  >
    <SrOnlySpan>Close</SrOnlySpan>
    <IconSpan>
      <IconClose color="neutralDark" variant="solid" size="xxsmall" />
    </IconSpan>
  </CloseButton>
);

export const BannerView = ({
  index,
  bannerId,
  type,
  heading,
  message,
  onCloseButtonClick,
  closeBtnDataId,
}: IBannerView) => {
  return (
    <ParentDiv
      data-id={`banner-${bannerId}`}
      type={type}
      className="accelerated-element"
    >
      <Container>
        <WrapperDiv>
          <LeftDiv>
            <SrOnlySpan>Information</SrOnlySpan>
            {type === "warning" ? (
              <IconWarning variant="solid" />
            ) : (
              <IconInfo color="info" variant="solid" />
            )}
          </LeftDiv>
          <RightDiv>
            <TitleDiv>
              <NotificationHeader
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              {index === 0 && (
                <CloseIconButton
                  type={type}
                  onCloseButtonClick={onCloseButtonClick}
                  closeBtnDataId={closeBtnDataId}
                />
              )}
            </TitleDiv>
            <div>
              <Paragraph dangerouslySetInnerHTML={{ __html: message }} />
            </div>
          </RightDiv>
        </WrapperDiv>
      </Container>
    </ParentDiv>
  );
};
