import * as React from "react";
import styled from "styled-components";
import { navTo } from "@origin-digital/event-dispatcher";
import { Logo } from "../Svg/Logo";
import { BREAK_POINTS } from "../../consts/style";
import { StyledLink, LinkProps } from "../Link";

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey200};
  background-color: ${(p) => p.theme.colors.white};
`;

const NavLink = styled(StyledLink)<LinkProps>`
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;

  & > img {
    height: 24px;
    width: 24px;
    vertical-align: middle;
  }
  & > span {
    visibility: hidden;
    margin-left: -10000px;
    position: absolute;
  }
  & > svg {
    fill: #ababab;
    width: 33px;
    height: 33px;
    padding: 0;
  }

  &.active {
    > span {
      border-bottom: 3px solid #ffb432;
      padding-bottom: 5px;
    }
  }
  @media (min-width: ${BREAK_POINTS.MIN.MD}) {
    line-height: 100%;
    & > svg {
      width: 22px;
      height: 22px;
      position: relative;
      top: -2px;
      padding: 0;
    }
    & > span {
      font-size: 12px;
      font-weight: 500;
      visibility: visible;
      margin: 0;
      height: 12px;
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: 3px;
      padding-left: 1px;
    }
  }
`;

export const BorderedNavLink = styled(NavLink)<LinkProps>`
  padding: 14px;

  @media (min-width: ${BREAK_POINTS.MIN.LG}) {
    &:first-child {
      border-left: 1px solid #e3e3e3;
    }
  }

  @media (min-width: ${BREAK_POINTS.MIN.MD}) {
    border-right: 1px solid #e3e3e3;
    height: 100%;
    padding: 14px 32px;
  }
`;

const SmallLogoLink = styled(BorderedNavLink)`
  padding: 7px;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  float: left;
  position: relative;

  & > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    box-sizing: border-box;
  }
`;

const ScopedAuthLogo = styled(SmallLogoLink)`
  border-right: none;
`;

export const NoNavigationHeader = () => (
  <Container data-id="no-nav-header">
    <ScopedAuthLogo
      data-id="no-nav-logolink"
      href="#"
      onClick={() => navTo.home()}
    >
      <Logo id="no-nav-logo" />
    </ScopedAuthLogo>
  </Container>
);
