import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const lpgPaymentExtension: IMcAppType = {
  name: "lpgPaymentExtension",
  path: "/my/assistance/lpg/payment-extension",
  description: "Request a payment extension for LPG",
  navFlow: NavFlow.FOCUS,
  title: "Payment extension",
  parent: Tab.DASHBOARD,
  conditions: {
    intent: "lpgPaymentExtension",
  },
};
