import React, { useEffect } from "react";
/* eslint-disable import/no-extraneous-dependencies */
import Popover from "@mui/material/Popover";
/* eslint-enable */

import { IconChevronDownSmall, IconClose } from "@origin-digital/ods-icons";

import { INavigationItem } from "@origin-digital/navigation-client-sdk";
import { DEFAULT_RED } from "@origin-digital/ods-consts";
import { Box, IconButton, Stack } from "@origin-digital/ods-core";
import styled from "styled-components";
import { NavLink } from "../NavLink";

const DropDownContainer = styled(Box)(({ theme: { palette } }) => ({
  cursor: "pointer",

  "&:focus": {
    outline: "1px dotted #212121, 5px auto -webkit-focus-ring-color",
  },

  '&[aria-owns="mouse-over-popover"] *': {
    color: DEFAULT_RED,
  },

  '&[aria-owns="mouse-over-popover"] .MuiSvgIcon-root *': {
    color: palette.primary.main,
  },
}));

const ParentLinkContainer = styled(Box)(({ theme: { space } }) => ({
  cursor: "pointer",

  "& .MuiSvgIcon-root": {
    marginLeft: space.xxsmall,
  },
}));

const PopoverContent = styled(Box)({
  minWidth: 380,
  minHeight: "max-content",
  padding: 32,
  display: "flex",
  alignItems: "center",
});

const CloseIcon = styled(Box)({
  top: 16,
  right: 16,
});

const StyledPopover = styled(Popover)({
  pointerEvents: "none",
  "& .MuiPopover-paper": {
    pointerEvents: "auto",
    boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.08)",
    borderRadius: 0,
  },
});

interface IKrakenDropdown {
  dropdownList: INavigationItem[];
  id: string;
  children: React.ReactNode;
}

export const KrakenDropdown = (props: IKrakenDropdown) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { dropdownList, children, id } = props;
  const open = !!anchorEl;

  const handlePopoverOpen = () => {
    setAnchorEl(document.getElementById(id));
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverToggle = () => {
    if (open) {
      handlePopoverClose();
    } else {
      handlePopoverOpen();
    }
  };

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if ((event.target as Element).id === id) {
        if (["Space", "Enter", "ArrowDown"].includes(event.code)) {
          handlePopoverOpen();
        }
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <DropDownContainer
      id={id}
      data-id={id}
      display="inline-flex"
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      tabIndex={0}
      onClick={handlePopoverToggle}
      domProps={{
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose,
      }}
    >
      <ParentLinkContainer display="flex" alignItems="center">
        {children}
        <IconChevronDownSmall
          color="neutralDark"
          variant="solid"
          size="xxsmall"
        />
      </ParentLinkContainer>
      <StyledPopover
        role="menu"
        data-id="dropdown-popover"
        disableEnforceFocus
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <PopoverContent>
          <Stack space="medium">
            {dropdownList.map((navLink, index) => (
              <NavLink linkConfig={navLink} key={index} />
            ))}
          </Stack>
          <CloseIcon position="absolute">
            <IconButton
              aria-label="Close sub menu"
              onClick={handlePopoverClose}
              onBlur={handlePopoverClose}
            >
              <IconClose color="neutralDark" size="xxsmall" variant="solid" />
            </IconButton>
          </CloseIcon>
        </PopoverContent>
      </StyledPopover>
    </DropDownContainer>
  );
};
