import * as React from "react";
import { KrakenAuthNav } from "@origin-digital/myaccount-navigation";
import { MasqueradeBanner } from "../Banner/MasqueradeBanner";
import { Header } from "../Header";
import { Navigation } from "../Navigation";
import { NoNavigationHeader } from "../Navigation/NoNavigationHeader";
import { Survey } from "../Survey";

interface BaseAppContentProps {
  actionBar?: Window["oetal"]["pageData"]["actionBar"];
  showHeader: Window["oetal"]["pageData"]["showHeader"];
  enableCrisisBanner: Window["oetal"]["pageData"]["enableCrisisBanner"];
  title: string;
  isNative?: boolean;
}

export const UnauthAppContent: React.FC<BaseAppContentProps> = ({
  showHeader,
  actionBar = {},
  title,
  enableCrisisBanner = true,
  isNative,
}) => {
  return (
    <>
      {showHeader === true && <Navigation />}
      {showHeader === "scoped" && <NoNavigationHeader />}
      <Header
        title={actionBar.title || title}
        desktopVariant={actionBar.desktopVariant}
        headingComponent={actionBar.component}
        isNative={isNative}
        hideActionBar={actionBar.hidden}
        mobileNavButton={actionBar.mobileNavButton}
        breadcrumbs={actionBar.breadcrumbs}
        enableCrisisBanner={enableCrisisBanner}
        showPropertySelector={actionBar.showPropertySelector}
      />
    </>
  );
};

export const KrakenAppContent: React.FC<BaseAppContentProps> = ({
  actionBar = {},
  showHeader,
  title,
  enableCrisisBanner = true,
  isNative,
}) => {
  return (
    <>
      <MasqueradeBanner />
      {showHeader && (
        <KrakenAuthNav desktopVariant={actionBar.desktopVariant} />
      )}

      <Header
        title={actionBar.title || title}
        desktopVariant={actionBar.desktopVariant}
        headingComponent={actionBar.component}
        hideActionBar={actionBar.hidden}
        isNative={isNative}
        mobileNavButton={actionBar.mobileNavButton}
        breadcrumbs={actionBar.breadcrumbs}
        enableCrisisBanner={enableCrisisBanner}
        showPropertySelector={actionBar.showPropertySelector}
      />
      <Survey />
    </>
  );
};
