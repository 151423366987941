/* eslint-disable @typescript-eslint/no-use-before-define */
import { IAuthResult, IAuthTokens } from "../interfaces";
import { decodeJwt } from "./jwtUtils";

const CUSTOMER_TYPE_CLAIM_KEY = "https://originenergy.com.au/customerType";
const CUSTOMER_TYPE_CLAIM_KRAKEN = "kraken";
export const ALLOWED_DOMAINS = [
  "origindigital-dac.com.au",
  "origindigital-pac.com.au",
  "localhost",
  "originenergy.com.au",
  "originzero.com.au",
  "origin.com.au",
  "originrx-tac.com.au",
  "originrx-pac.com.au",
];

/**
 * Validates the current user is authenticated and has valid, non-expired accessTokens and
 * idTokens
 *@returns boolean - flag indicating if user is authenticated
 *
 */
export function isAuthenticated(tokens?: IAuthTokens): boolean {
  if (tokens) {
    const { access_token, id_token, expires_at } = tokens;
    const isExpired = expires_at ? new Date() > new Date(expires_at) : true;
    return !!(access_token && id_token && !isExpired);
  } else {
    return false;
  }
}

export function isKrakenCustomer(accessToken: string): boolean {
  return isCustomerType(accessToken, CUSTOMER_TYPE_CLAIM_KRAKEN);
}

export function isCustomerType(
  accessToken: string,
  customerType: string
): boolean {
  const decodedJwt = decodeJwt(accessToken);
  return (
    !!decodedJwt.payload[CUSTOMER_TYPE_CLAIM_KEY] &&
    decodedJwt.payload[CUSTOMER_TYPE_CLAIM_KEY] === customerType
  );
}

/**
 * Checks if user has a valid current auth session. Existence of an auth session
 * is indicated by the presence of a non-expired sap token cookie
 */
export function getCookie(name: string): string | undefined {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) return match[2];
  return undefined;
}

export function deleteCookie(name: string, options?: { domain?: string }) {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${
    options?.domain ? `domain=${options.domain}; ` : ""
  }`;
}

export function isEachTokenNotEmpty(authResult: Partial<IAuthResult>): boolean {
  return !!(
    authResult &&
    authResult.accessToken &&
    authResult.idToken &&
    authResult.expiresIn
  );
}

/**
 * This is to resolve some versions of IE returning "undefined" for window.location.origin
 * @returns window.location.origin
 *
 */
export function getLocationOrigin() {
  const { origin, protocol, hostname, port } = window.location;
  const optionalPort = port ? `:${port}` : "";
  return origin || `${protocol}//${hostname}${optionalPort}`;
}

export function getAllowedDomains() {
  return ALLOWED_DOMAINS;
}

export function createAuthTokens(authResult: IAuthResult): IAuthTokens {
  if (!isEachTokenNotEmpty(authResult)) {
    // eslint-disable-next-line no-throw-literal
    throw "createAuthTokens:: Auth tokens are empty";
  }
  return {
    access_token: authResult.accessToken,
    id_token: authResult.idToken,
    expires_at: Date.now() + parseInt(authResult.expiresIn, 10) * 1000,
  };
}

/**
 * Removes any hash from the location string
 */
export function clearHash(): void {
  window.location.hash = "";
}

export const matchesOneElement = (
  list1: string[] = [],
  list2: string[] = []
): boolean => {
  return list1.some((el) => list2.indexOf(el) !== -1);
};
