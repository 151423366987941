import { Button } from "@origin-digital/ods-core";
import React from "react";
import styled from "styled-components";

const MyAccountWrapper = styled.div`
  /* default to display none, and let the Wrapper decide if this should display */
  display: none;

  /* a dumb hack to make display the button properly inline with the rest of the grid */
  & a {
    display: block;
  }
  /* text-decoration: none;
  color: ${(p) => p.theme.colors.primary};
  font-size: 14px; */
`;

const LoginWrapper = styled.div`
  /* default to display none, and let the Wrapper decide if this should display */
  display: none;
`;

const Wrapper = styled.div`
  margin: auto 0;
  display: flex;
  /* Not really needed but there is a barely visible flicker from when the element is
  initially display none. This min-width reduces the flicker further by forcing a space
  even when neither button exists
  */
  min-width: 83px;

  &.unauthed > ${LoginWrapper} {
    display: block;
  }
  &.authed > ${MyAccountWrapper} {
    display: block;
  }
`;

const switcherClass = "tal_myaccount-login-switcher";

// CAUTION: This code does not get polyfilled, this is because in order to let
// webpack polyfill it, we have to wait for the webpack runtime to be loaded to
// run this code. This defeats the purpose as we want this to be a very light
// script that can run inline/blocking with the SSR'd content, without taking on
// the overhead of loading Webpack.

// IMPORTANT: make sure that you right code here that is compatible with all
// browsers we even somewhat support.
// See .browserlistrc for what we should be supporting

// Try to keep this idempotent, that way if it does run multiple times (because maybe
// we don't properly detect ssr/some other reason), it will still do the right thing
const run = `
(() => {
    let isAuthed = false
    try {
        isAuthed =
            Object.keys(localStorage).some((k) =>
                k.startsWith("@@auth0spajs@@")
            )
    } catch (e) {
        // do nothing, not authed
    }

    const els = document.querySelectorAll(".${switcherClass}")

    els.forEach(el => {
      el.classList.remove("authed", "unauthed")

      if (isAuthed) el.classList.add("authed")
      else el.classList.add("unauthed")
    })

  })()
`;

// Don't use state in this component. We don't want React to track/update this
// component as we will manually be updating it, before react is loaded.
export const SSRLoginSwitcher = () => {
  const ref = React.useRef<HTMLDivElement>(null);

  // Use Layout Effect runs after all of the dom elements created by the component are
  // actually created in the dom. Since we want to modify a dom element here, we should
  // use layout effect instead of effect
  React.useLayoutEffect(() => {
    // In non-ssr'd environments, the dangerouslySetInnerHTML defined below won't work.
    // This is because it is adding to the page using document.innerHTML, which does
    // not execute scripts (although it will add it to our SSR'd page, which when sent)
    // to the browser will execute. Because of this we have to forcefully run the script here.
    // NOTE: eval will be blocked by our CSP policy but adding a script tag won't be.
    if (!ref.current) return;

    const newScript = document.createElement("script");

    newScript.async = false;
    newScript.text = `// Script replaced and re-run during layoutEffect\n${run}`;

    ref.current.innerHTML = "";
    ref.current.appendChild(newScript);
  }, []);

  return (
    <div>
      <Wrapper className={switcherClass}>
        <MyAccountWrapper>
          <Button
            variant="text"
            size="small"
            href="/my"
            data-id="navigation:my-account"
            noTextPadding
          >
            My Account
          </Button>
        </MyAccountWrapper>
        <LoginWrapper>
          <Button
            size="small"
            href="/auth/callback"
            data-id="navigation:log-in"
          >
            Log in
          </Button>
        </LoginWrapper>
      </Wrapper>

      {/* We use dangerouslySetInnerHTML as an escape hatch to take on full manual control of this element.
      React will not track elements/changes in dangerouslySetInnerHTML which allows us to replace/rerun the
      script in useLayoutEffect without confusing the virtual dom https://stackoverflow.com/a/37339542 */}
      <div
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: `
        <script>
          // Script injected during SSR
          ${run}
          </script>`,
        }}
      />
    </div>
  );
};
