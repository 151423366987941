import React from "react";

import { Button, Hidden } from "@origin-digital/ods-core";
import styled from "styled-components";

const JoinUsWrapper = styled.div`
  display: none;
  margin-right: 8px;

  & a {
    display: block;
  }
`;

export const JoinUs = () => {
  return (
    <JoinUsWrapper data-id="navigation:join-origin-wrapper">
      <Hidden above="md">
        <Button
          variant="outlined"
          size="small"
          href="/electricity-gas/plans.html"
          data-id="navigation:join-origin"
        >
          Join
        </Button>
      </Hidden>
      <Hidden below="md">
        <Button
          variant="outlined"
          size="small"
          href="/electricity-gas/plans.html"
          data-id="navigation:join-origin"
        >
          Join Origin
        </Button>
      </Hidden>
    </JoinUsWrapper>
  );
};
