/* eslint-disable babel/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from "react";
import styled from "styled-components";

import {
  ActionBar,
  ActionBarProps as ActionBarPropsBase,
} from "@origin-digital/ods-action-bar";

import {
  AlertBanner,
  AlertBannerProps,
  Hidden,
  MarkdownLite,
  Text,
} from "@origin-digital/ods-core";

import {
  eventListener,
  IActionBarUpdate,
  IAlertBanner,
  IAlertBannerClose,
  IOriginEvents,
  ISubscription,
  navigationBack,
  navigationClose,
  TOPICS,
} from "@origin-digital/event-dispatcher";
import { Z_INDEX } from "../../consts/style";
import { CrisisBanner } from "../Banner";
import { PropertySelector } from "../PropertySelector/PropertySelector";
import { getBreadcrumbs } from "./utils";

const ACTION_BAR_HEIGHT = 129;

export type AlertStatusLabels =
  | "basic"
  | "error"
  | "info"
  | "success"
  | "warning";

// breadcrumbs are deprecated but still supported. Once removed, this type does not need to be redefined
interface ActionBarProps extends ActionBarPropsBase {
  breadcrumbs?: IActionBarUpdate["payload"]["breadcrumbs"];
}

interface AlertBannerContentProps {
  visible: boolean;
  message: string;
  heading?: string;
  type?: AlertStatusLabels;
  uniqueId?: string;
  hideCloseButton?: boolean;
}

export interface HeaderProps extends ActionBarProps {
  hideActionBar?: boolean | "desktop";
  isNative?: boolean;
  enableCrisisBanner?: boolean;
}

interface HeaderState {
  actionBar: ActionBarProps;
  alertBanner: AlertBannerContentProps;

  actionBarUpdateUnsubscribe?: ISubscription;
  alertBannerOpenUnsubscribe?: ISubscription;
  alertBannerCloseUnsubscribe?: ISubscription;
}

const mapAlertBannerType = (
  oldType?: AlertStatusLabels
): AlertBannerProps["tone"] => {
  if (oldType === "error") {
    return "critical";
  } else if (oldType === "warning") {
    return "caution";
  } else if (oldType === "success") {
    return "positive";
  } else if (oldType === "basic") {
    return "neutral";
  }
  return "info";
};

const HeaderContainer = styled.div<{
  desktopVariant: ActionBarProps["desktopVariant"];
}>`
  z-index: ${Z_INDEX.HEADER};
  position: sticky;
  top: 0;
  ${(p) =>
    p.desktopVariant !== "slim" &&
    `@media only screen and (min-width: ${p.theme.breakpoints.lg}) {
    top: -${ACTION_BAR_HEIGHT}px;
  }`}
`;

const AlertRelativeAnchor = styled.div`
  position: relative;
`;

const AlertContainer = styled.div<{ visible: boolean }>`
  pointer-events: ${(p) => (p.visible ? "unset" : "none")};
  position: absolute;
  width: 100%;

  /* disable transition out as the content will be gone from the state
   * once the close button is pressed */
  transition: all 0.2s ease-in-out;
  transition: ${(p) =>
    p.visible
      ? // Ease both opacity and transform when opening
        "all 0.2s ease-in-out"
      : // Ease the opacity out, but don't animate away the translateY
        // The linear function looks like this _| so the transform will
        // only happen at the end of the animation, when the opacity is
        // already at 0 (setting it up for the ease in when it appears)
        "opacity 0.2s ease-in-out, transform 0.2s linear(0 0%, 0 100%, 1 100%)"};
  opacity: ${(p) => (p.visible ? "1" : "0")};
  transform: translateY(${(p) => (p.visible ? "0" : `-10px`)});

  & > div[data-id="alert-banner"] {
    justify-content: center;
  }
`;

const BottomLine = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.grey200};
`;

export class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps, state: HeaderState) {
    super(props, state);
    const {
      title,
      mobileNavButton,
      headingComponent,
      breadcrumbs,
      desktopVariant,
      showPropertySelector,
    } = props;
    this.state = {
      actionBar: {
        title,
        mobileNavButton,
        headingComponent,
        breadcrumbs,
        desktopVariant,
        showPropertySelector,
      },
      alertBanner: {
        visible: false,
        hideCloseButton: false,
        message: "",
      },
    };
    this.handleOnCloseAlertBanner = this.handleOnCloseAlertBanner.bind(this);
  }

  componentDidMount() {
    const actionBarUpdateUnsubscribe = eventListener.addListener(
      TOPICS.ACTION_BAR_UPDATE,
      (topic: IOriginEvents) => {
        if (topic.topic === TOPICS.ACTION_BAR_UPDATE) {
          this.handleActionBarUpdate(topic);
        }
      }
    );

    const alertBannerOpenUnsubscribe = eventListener.addListener(
      TOPICS.ALERT_BANNER_OPEN,
      (topic: IOriginEvents) => {
        if (topic.topic === TOPICS.ALERT_BANNER_OPEN) {
          this.handleAlertBannerOpen(topic);
        }
      }
    );

    const alertBannerCloseUnsubscribe = eventListener.addListener(
      TOPICS.ALERT_BANNER_CLOSE,
      (topic: IOriginEvents) => {
        if (topic.topic === TOPICS.ALERT_BANNER_CLOSE) {
          this.handleAlertBannerClose(topic);
        }
      }
    );

    this.setState({
      actionBarUpdateUnsubscribe,
      alertBannerOpenUnsubscribe,
      alertBannerCloseUnsubscribe,
    });
  }

  componentWillUnmount() {
    this.state.actionBarUpdateUnsubscribe &&
      this.state.actionBarUpdateUnsubscribe();
    this.state.alertBannerOpenUnsubscribe &&
      this.state.alertBannerOpenUnsubscribe();
    this.state.alertBannerCloseUnsubscribe &&
      this.state.alertBannerCloseUnsubscribe();
  }

  handleActionBarUpdate(topic: IActionBarUpdate) {
    this.setState((prevState) => ({
      actionBar: {
        ...prevState.actionBar,
        ...topic.payload,
      },
    }));
  }

  handleAlertBannerOpen(topic: IAlertBanner) {
    this.setState({
      alertBanner: {
        visible: true,
        ...topic.payload,
      },
    });
  }

  handleAlertBannerClose(topic: IAlertBannerClose) {
    if (
      this.state.alertBanner &&
      this.state.alertBanner.uniqueId === topic.payload.uniqueId
    ) {
      this.handleOnCloseAlertBanner();
    }
  }

  handleOnClickMobileNavButton() {
    if (this.state.actionBar.mobileNavButton === "close") {
      navigationClose();
    } else if (typeof window !== "undefined") {
      navigationBack();
    }
  }

  handleOnCloseAlertBanner() {
    this.setState((state) => ({
      alertBanner: { ...state.alertBanner, visible: false },
    }));
  }

  render() {
    const { hideActionBar, isNative } = this.props;
    const { actionBar, alertBanner } = this.state;
    const breakpointToHide = hideActionBar === "desktop" ? "lg" : undefined;
    const showActionBar = hideActionBar === "desktop" ? true : !hideActionBar;
    const breadcrumbs = getBreadcrumbs(actionBar.breadcrumbs);
    const enableCrisisBanner =
      this.props.enableCrisisBanner === undefined
        ? true
        : this.props.enableCrisisBanner;

    return (
      <HeaderContainer desktopVariant={actionBar.desktopVariant}>
        {showActionBar ? (
          <Hidden above={breakpointToHide}>
            <ActionBar
              mobileNavButton={actionBar.mobileNavButton}
              headingComponent={actionBar.headingComponent}
              desktopVariant={actionBar.desktopVariant}
              onClickMobileNavButton={() => this.handleOnClickMobileNavButton()}
              title={actionBar.title}
              breadcrumbs={breadcrumbs}
              showPropertySelector={actionBar.showPropertySelector}
              propertySelector={<PropertySelector />}
            />
            <BottomLine className="accelerated-element" />
          </Hidden>
        ) : null}
        {isNative && enableCrisisBanner ? <CrisisBanner /> : null}
        <AlertRelativeAnchor>
          <AlertContainer visible={alertBanner.visible}>
            {/* We have to set defaults for these mandatory fields so that
                the element can already exist in the dom (hidden) and we
                can then transition it */}
            <AlertBanner
              title={alertBanner?.heading || ""}
              tone={mapAlertBannerType(alertBanner?.type || "info")}
              onCloseClick={
                this.state.alertBanner.hideCloseButton
                  ? undefined
                  : this.handleOnCloseAlertBanner
              }
            >
              <Text>
                <MarkdownLite>{alertBanner?.message || ""}</MarkdownLite>
              </Text>
            </AlertBanner>
          </AlertContainer>
        </AlertRelativeAnchor>
      </HeaderContainer>
    );
  }
}
