import { CustomerType } from "@origin-digital/platform-enums";
import {
  IMcAppType,
  NavFlow,
  Tab,
  salesCommonParameters,
} from "../mcApp.types";

export const move_UNAUTH: IMcAppType = {
  name: "move_UNAUTH",
  path: "/moving/",
  parameters: {
    properties: {
      book: {
        type: "boolean",
        description: "Show 'Book my Move' button",
      },
    },
  },
  description: "Move house",
  navFlow: NavFlow.EXTERNAL_AUTH,
  title: "Move",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "move",
    customerType: CustomerType.unauthenticated,
  },
};

export const move_KRAKEN: IMcAppType = {
  name: "move_KRAKEN",
  path: "/my/move-address",
  description: "Landing page for residential authenticated move",
  navFlow: NavFlow.STANDARD,
  title: "Move home",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410
  conditions: {
    intent: "move",
    customerType: CustomerType.kraken,
  },
};

export const salesFormAuthMoveResi: IMcAppType = {
  name: "salesFormAuthMoveResi",
  path: "/my/move-home",
  parameters: {
    properties: {
      journeyType: salesCommonParameters.journeyType,
      planFetchStrategy: salesCommonParameters.planFetchStrategy,
      fuel: salesCommonParameters.fuel,
      existingFuel: salesCommonParameters.fuel,
      originAddressId: salesCommonParameters.originAddressId,
    },
    required: [
      "journeyType",
      "planFetchStrategy",
      "fuel",
      "existingFuel",
      "originAddressId",
    ],
  },
  description: "(Internal) Sales form entry for RESI authenticated move",
  navFlow: NavFlow.FOCUS,
  title: "Move home",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormAuthMoveResi",
    customerType: CustomerType.kraken,
  },
};

// new sales form auth move for businessnavigation
export const salesFormAuthMoveBusiness: IMcAppType = {
  name: "salesFormAuthMoveBusiness",
  path: "/my/business/move-home",
  parameters: {
    properties: {
      journeyType: salesCommonParameters.journeyType,
      planFetchStrategy: salesCommonParameters.planFetchStrategy,
      fuel: salesCommonParameters.fuel,
      existingFuel: salesCommonParameters.existingFuel,
      originAddressId: salesCommonParameters.originAddressId,
    },
    required: [
      "journeyType",
      "planFetchStrategy",
      "fuel",
      "existingFuel",
      "originAddressId",
    ],
  },
  description: "(Internal) Sales form entry for SME authenticated move",
  navFlow: NavFlow.FOCUS,
  title: "Move home",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormAuthMoveBusiness",
    customerType: CustomerType.kraken,
  },
};
