import { CustomerType } from "@origin-digital/platform-enums";
import {
  IMcAppType,
  NavFlow,
  salesCommonParameters,
  Tab,
} from "../mcApp.types";

export const renewalPlanSignup: IMcAppType = {
  name: "renewalPlanSignup",
  path: "/my/auth-renew-signup",
  description: "This is the landing page for Authenticated renewal",
  navFlow: NavFlow.STANDARD,
  title: "Renew your plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "renewalPlanSignup",
    customerType: CustomerType.kraken,
  },
};

export const renewPlanFocused: IMcAppType = {
  name: "renewPlanFocused",
  path: "/my/auth-renew-signup/renew",
  description:
    "This is the landing page for Authenticated renewal loaded as Focus Flow - setup for IVR",
  navFlow: NavFlow.FOCUS,
  title: "Renew your plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "renewPlanFocused",
    customerType: CustomerType.kraken,
  },
};

export const changePlanSelection: IMcAppType = {
  name: "changePlanSelection",
  path: "/my/auth-change-plan",
  description: "This is the landing page for Change plans",
  navFlow: NavFlow.FOCUS,
  title: "Change your plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "changePlanSelection",
    customerType: CustomerType.kraken,
  },
};

// new authenticated renewal plan selection page
export const renewalPlanSelection: IMcAppType = {
  name: "renewalPlanSelection",
  path: "/my/auth-renew-signup/plan-selector",
  description:
    "(Internal) Plan Selection page for Auth Renew.  Can link to here directly, but journey should really start on the landing page",
  navFlow: NavFlow.FOCUS,
  title: "Renew your plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "renewalPlanSelection",
    customerType: CustomerType.kraken,
  },
};

// new sales form auth renew navigation
export const salesFormAuthRenew: IMcAppType = {
  name: "salesFormAuthRenew",
  path: "/my/renew-plan",
  parameters: {
    properties: {
      journeyType: salesCommonParameters.journeyType,
      originAddressId: salesCommonParameters.originAddressId,
      useCart: salesCommonParameters.useCart,
    },
    required: ["journeyType", "originAddressId"],
  },
  description: "(Internal) resi auth renew entry into the sales form",
  navFlow: NavFlow.MODAL,
  title: "Renew your plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormAuthRenew",
    customerType: CustomerType.kraken,
  },
};

export const salesFormSmeAuthRenew: IMcAppType = {
  name: "salesFormSmeAuthRenew",
  path: "/my/business/renew-plan",
  parameters: {
    properties: {
      journeyType: salesCommonParameters.journeyType,
      originAddressId: salesCommonParameters.originAddressId,
      useCart: salesCommonParameters.useCart,
    },
    required: ["journeyType", "originAddressId"],
  },
  description: "(Internal) SME auth renew entry into the sales form",
  navFlow: NavFlow.MODAL,
  title: "Renew your plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormSmeAuthRenew",
    customerType: CustomerType.kraken,
  },
};
