import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  eventListener,
  TOPICS,
  type ISubscribeSelectedProperty,
  type IPropertyWithIds,
} from "@origin-digital/event-dispatcher";
import {
  fetchUserContextV3,
  type IUser,
  readPropertyFromStorage,
  writePropertyToStorage,
} from "./UserContextService";

export type UserContextType = {
  setProperty: (p: IPropertyWithIds | null) => void;
  property: IPropertyWithIds | null;
  user?: IUser;
  loading: boolean;
};

export const UserContext = createContext<UserContextType>({
  setProperty: () => {},
  property: null,
  user: undefined,
  loading: false,
});

export const useUserContext = () => useContext(UserContext);

//Instantiated outside the component, because we don't want to have it linked to react lifecycle
const subscribers: ((
  property: IPropertyWithIds | null
) => IPropertyWithIds | null)[] = [];

const handleSubscribeProperty = (event: ISubscribeSelectedProperty) => {
  const subscriber = event.payload.subscriber;
  subscribers.push(subscriber);
  return () => subscribers.splice(subscribers.indexOf(subscriber), 1);
};

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser>();
  const [property, setProperty] = useState<IPropertyWithIds | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  // Refetch context to avoid stale data.
  // Daxi cache ttl controls data age, this just refreshes from Daxi
  // Would be better to leverage tanstack query autofetch
  const [shouldFetch, setShouldFetch] = useState(true);

  const loadUser = useCallback(async () => {
    setLoading(true);
    setShouldFetch(false);
    setTimeout(() => {
      setShouldFetch(true);
    }, 900000); //15 minutes
    const fetchedUser = await fetchUserContextV3();
    setUser(fetchedUser);
    const loadedProperty = readPropertyFromStorage();
    setProperty(loadedProperty ?? null);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      loadUser();
    }
  }, [shouldFetch, loadUser]);

  useEffect(() => {
    if (!loading) {
      subscribers.forEach((subscriber) => {
        subscriber(property);
      });
      writePropertyToStorage(property);
    }
  }, [property, loading]);

  useEffect(() => {
    const wait = (ms: number) =>
      new Promise((resolve: (_: void) => void) => {
        setTimeout(() => resolve(), ms);
      });
    let retry = 0;
    const handleFetchContext = async () => {
      if (!user && loading) {
        // Wait for user to be ready
        if (retry < 5) {
          retry++;
          await wait(100);
          return handleFetchContext();
        }
        return undefined;
      }
      return user;
    };
    const unsubFetchUser = eventListener.addListener(
      TOPICS.FETCH_USER_CONTEXT,
      handleFetchContext
    );
    return () => {
      unsubFetchUser();
    };
  }, [user, loadUser]);

  useEffect(() => {
    const unsubSubscribeProperty = eventListener.addListener(
      TOPICS.SUBSCRIBE_SELECTED_PROPERTY,
      handleSubscribeProperty
    );
    return () => {
      unsubSubscribeProperty();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setProperty,
        property,
        loading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
