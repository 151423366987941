export function shouldInitialize(): boolean {
  const platformApps = [
    "/auth/callback",
    "/logout",
    "/auth/ml",
    "/auth/sml",
    "/my-account/contexthub",
  ];

  // Use globalThis so that there isn't a undeclared "window" variable in a node context (SSR)
  const pathName = globalThis?.window?.location?.pathname;
  if (!pathName) {
    return false;
  }

  const isPlatformApp = platformApps.some((app) => app === pathName);

  // Do not initialise for platform specific and kraken apps
  if (isPlatformApp) {
    return false;
  }

  return true;
}
