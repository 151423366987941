import React, { useState } from "react";
import { type IProperty } from "@origin-digital/user-context-provider/src/UserContext/UserContextService";
import { Box, Button, Heading, Sheet } from "@origin-digital/ods-core";
import { IPropertyWithIds } from "@origin-digital/event-dispatcher";
import { TileButton, TileButtonGroup } from "@origin-digital/ods-tile-button";
import { PropertySheetBottomLinks } from "./PropertySheetBottomLinks";

interface PropertySheetProps {
  selectedProperty: IPropertyWithIds | null;
  setProperty: (property: IPropertyWithIds | null) => void;
  properties: (IPropertyWithIds | undefined)[];
  inactiveProperties: IPropertyWithIds[];
  open?: boolean;
  setOpen: (show: boolean) => void;
}

const PropertyCard = ({
  property,
  selected = false,
}: {
  property?: IProperty;
  selected?: boolean;
}) => {
  const label = property ? property.label : "ALL ACTIVE PROPERTIES";
  const id = property?.id ?? "all-properties";
  return (
    <TileButton
      key={id}
      value={property?.id ?? ""}
      id={id}
      label={label}
      togglePosition="left"
      selected={selected}
      aria-label={label}
    />
  );
};

export const PropertySheet: React.FC<PropertySheetProps> = ({
  selectedProperty,
  properties,
  inactiveProperties,
  setProperty,
  open = false,
  setOpen,
}) => {
  const mapPropertyCards = (p?: IProperty) => (
    <PropertyCard property={p} selected={selectedProperty?.id === p?.id} />
  );

  const [showInactive, setShowInactive] = useState(false);
  const allProperties = inactiveProperties
    ? [...properties, ...inactiveProperties]
    : properties;
  // @ts-ignore
  return (
    <Sheet
      id="property-sheet"
      title="Properties"
      data-id="property-sheet"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      dialogMaxWidth="xs"
    >
      <TileButtonGroup
        id="property-sheet-group"
        value={selectedProperty?.id ?? ""}
        hideHelperTextSpace={true}
        onChange={(_e, v) => {
          const sp = allProperties.find((p) => p?.id === v) ?? null;
          setProperty(sp);
          setOpen(false);
        }}
      >
        {[
          <PropertyCard key="null" selected={selectedProperty === null} />,
          ...properties.map(mapPropertyCards),
          inactiveProperties.length > 0 && !showInactive && (
            <Button
              key="show-inactive-button"
              noTextPadding
              variant="text"
              size="small"
              onClick={() => setShowInactive(true)}
            >
              Show previous properties
            </Button>
          ),
          showInactive && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Heading variant="h4">Previous Properties</Heading>
              <Button
                noTextPadding
                variant="text"
                size="small"
                onClick={() => setShowInactive(false)}
              >
                Hide
              </Button>
            </Box>
          ),
          showInactive && inactiveProperties?.map(mapPropertyCards)[0],
        ].filter((e) => e !== false)}
      </TileButtonGroup>
      <PropertySheetBottomLinks closeSheet={() => setOpen(false)} />
    </Sheet>
  );
};
