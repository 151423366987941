import React from "react";
/* eslint-disable import/no-extraneous-dependencies */
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
/* eslint-enable */
import { IconClose } from "@origin-digital/ods-icons";
import { Box, Divider, IconButton } from "@origin-digital/ods-core";

import { useNavigation } from "@origin-digital/navigation-client-sdk";
import styled from "styled-components";

import { MenuHamburger } from "./MenuHamburger";
import { MobileNavLinkGroup } from "./MobileNavLinkGroup";

const StyledAnchorLeft = styled(SwipeableDrawer)(
  ({ theme: { breakpoints } }) => ({
    "& .MuiDrawer-paper": { maxWidth: breakpoints.values.sm, width: "100%" },
  })
);

const StyledCloseDrawerListItem = styled(Box)(({ theme }) => ({
  height: 64,
  display: "flex",
  alignItems: "center",
  '& [class*="MuiIconButton-root"]': {
    padding: 5,

    "& .MuiSvgIcon-root": {
      fontSize: theme.shape.iconSizes.xxsmall,
    },
  },
}));

const scrollToOpenNav = (name: string) => {
  const menu = document.getElementById("mobile-menu");
  const menuGroup = document.getElementById(`mobile-nav-link-group-${name}`);

  const menuGroupTop = menuGroup?.offsetTop;
  menu?.scrollTo({ top: menuGroupTop });
};

export const MobileNav = () => {
  const { data, loading } = useNavigation();

  const navData = data?.children;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState<string>();

  const setDrawerVisibility =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      // Capture keyboard events for tabbing and shifting through
      if (
        !(
          (event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
              (event as React.KeyboardEvent).key === "Shift")) ||
          (event as React.KeyboardEvent).metaKey
        )
      ) {
        setMobileOpen(open);
      }
    };

  const handleDropdownHeaderClick = (name: string) => {
    if (openDropdown === name) {
      setOpenDropdown(undefined);
    } else {
      setOpenDropdown(name);
      // adding a tiny delay to ensure the menu has opened before we scroll
      setTimeout(() => scrollToOpenNav(name), 100);
    }
  };

  // This is for optimisation on iOS devices
  const iOS =
    typeof window !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <MenuHamburger
        onClick={setDrawerVisibility(true)}
        data-id="hamburger-button"
        aria-label="Open Menu"
      />
      <StyledAnchorLeft
        anchor="left"
        open={mobileOpen}
        onClose={setDrawerVisibility(false)}
        onOpen={setDrawerVisibility(true)}
        disableDiscovery={iOS}
        data-id="drawer-menu"
        PaperProps={{ id: "mobile-menu" }}
      >
        <Box display="flex" flexDirection="column">
          <StyledCloseDrawerListItem marginLeft="small">
            <IconButton
              onClick={() => setMobileOpen(false)}
              data-id="close-menu-button"
              aria-label="Close menu"
            >
              <IconClose color="neutralDark" size="xxsmall" variant="solid" />
            </IconButton>
          </StyledCloseDrawerListItem>

          {loading
            ? null
            : navData?.map((navLink) => (
                <MobileNavLinkGroup
                  key={navLink.name}
                  setDrawerVisibility={setDrawerVisibility(false)}
                  linkConfig={navLink}
                  isOpen={navLink.name === openDropdown}
                  setDropdownOpen={handleDropdownHeaderClick}
                />
              ))}
        </Box>
        <Divider />
      </StyledAnchorLeft>
    </>
  );
};
