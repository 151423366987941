import { CustomerType } from "@origin-digital/platform-enums";
import {
  BasicJSONSchema,
  CommonParameters,
  IMcAppType,
  NavFlow,
  Tab,
} from "../mcApp.types";

const serviceIdParameter: BasicJSONSchema = {
  description: "LPG service id",
  type: "string",
};

// TODO this can be removed when we migrate to /my/usage routes
export const serviceTypes: IMcAppType[] = [
  "electricity",
  "natural-gas",
  "hot-water",
  "embedded-electricity",
].map((name) => ({
  name: `service_KRAKEN_${name.toUpperCase().replaceAll("-", "_")}`,
  path: `/my/service/${name}/:agreementId`,
  description: "Service overview",
  parameters: {
    required: ["serviceType", "agreementId"],
    properties: {
      agreementId: CommonParameters.agreementId,
      serviceType: CommonParameters.serviceType,
    },
  },
  navFlow: NavFlow.STANDARD,
  title: "Service",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "service",
    customerType: CustomerType.kraken,
    parameters: {
      serviceType: name,
    },
  },
}));

export const service: IMcAppType = {
  name: "service_KRAKEN",
  path: "/my/service/:serviceType/:agreementId",
  description: "Service overview",
  parameters: {
    required: ["serviceType", "agreementId"],
    properties: {
      agreementId: CommonParameters.agreementId,
      serviceType: CommonParameters.serviceType,
    },
  },
  navFlow: NavFlow.STANDARD,
  title: "Service",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "service",
    customerType: CustomerType.kraken,
  },
  ignorePathMapping: true,
};

export const lpgServiceAccessDetails: IMcAppType = {
  name: "lpgServiceAccessDetails",
  path: "/my/service/lpg/access-details",
  description: "LPG access details for all services",
  navFlow: NavFlow.STANDARD,
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  title: "Access details",
  conditions: {
    intent: "lpgAccessDetails",
    customerType: CustomerType.kraken,
  },
};

export const lpgServiceAccessDetailsEdit: IMcAppType = {
  name: "lpgServiceAccessDetailsEdit",
  path: "/my/service/lpg/access-details/:serviceId/edit",
  parameters: {
    required: ["serviceId"],
    properties: {
      serviceId: serviceIdParameter,
    },
  },
  description: "Edit LPG access details for a service",
  navFlow: NavFlow.FOCUS,
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  title: "Access details",
  conditions: {
    intent: "lpgAccessDetailsEdit",
    customerType: CustomerType.kraken,
  },
};

export const serviceApps: IMcAppType[] = [
  ...serviceTypes,
  service,
  lpgServiceAccessDetails,
  lpgServiceAccessDetailsEdit,
];
