import React from "react";

export const LandscapeLogo = (props) => (
  <svg
    width="106"
    height="32"
    viewBox="0 0 106 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Origin Energy Logo</title>
    <path
      d="M7.75,15.77c0-4.45,3.62-8.05,8.08-8.05s8.08,3.6,8.08,8.05-3.62,8.05-8.08,8.05c-4.46,0-8.08-3.6-8.08-8.05M15.83,0C7.08,0,0,7.06,0,15.77c0,5.22,2.54,9.84,6.46,12.71,2.62-1.92,5.86-3.06,9.36-3.06s6.74,1.14,9.36,3.06c3.92-2.87,6.46-7.49,6.46-12.71,0-8.71-7.08-15.77-15.83-15.77ZM45.4,12.4c-1.87,0-3.4,1.55-3.4,3.36s1.53,3.39,3.4,3.39,3.43-1.55,3.43-3.39c0-1.81-1.53-3.36-3.43-3.36M45.4,22.2c-3.63,0-6.54-2.9-6.54-6.44s2.91-6.41,6.54-6.41,6.57,2.85,6.57,6.41-2.91,6.44-6.57,6.44M54.56,21.86h3.19v-6.21c0-2.12.67-3.26,4.75-3.26v-3.05c-2.39,0-3.56.26-4.75,1.14v-.9h-3.19v12.28h0ZM64.87,21.86h3.19v-12.28h-3.19v12.28ZM87.68,21.86h3.19v-12.28h-3.19v12.28ZM102.81,21.86h3.19v-7.09c0-3.86-2.34-5.43-5.03-5.43-1.69,0-2.54.62-3.25,1.14v-.9h-3.19v12.28h3.19v-6.62c0-2.35,1.27-2.85,2.54-2.85s2.54.52,2.54,2.9v6.57h0ZM81,25.94c-.2,1.67-1.64,3.01-3.4,3.01-1.87,0-3.4-1.55-3.4-3.39s1.53-3.36,3.4-3.36c1.76,0,3.21,1.34,3.4,2.98.02.12.02.25.02.38,0,.13,0,.26-.02.39M74.2,15.76c0-1.81,1.53-3.36,3.4-3.36,1.76,0,3.21,1.34,3.4,2.98.02.13.02.25.02.38s0,.26-.02.38c-.2,1.67-1.64,3.01-3.4,3.01-1.87,0-3.4-1.55-3.4-3.39M84.17,15.76v-6.18h-3.15v.69s-.01-.01-.02-.02c-.98-.58-2.15-.91-3.4-.91-3.63,0-6.54,2.85-6.54,6.42,0,1.96.89,3.72,2.31,4.9-1.41,1.17-2.31,2.92-2.31,4.89,0,3.54,2.91,6.44,6.54,6.44s6.57-2.9,6.57-6.44c0-1.97-.89-3.72-2.31-4.89,1.42-1.18,2.31-2.94,2.31-4.9"
      fill="#ff6124"
      strokeWidth="0"
    />
  </svg>
);
