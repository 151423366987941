import React from "react";

import { IconMenu } from "@origin-digital/ods-icons";
import { Box, IconButton } from "@origin-digital/ods-core";

import styled from "styled-components";
import { navigationBarHeight } from "../helpers/consts";

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  display: "flex",
  alignItems: "center",
  height: navigationBarHeight,
  borderRight: `1px solid ${palette.grey["200"]}`,
  paddingRight: 16,
  '& [class*="MuiIconButton-root"]': {
    background: "transparent",
    padding: 0,
    "& svg": {
      fontSize: 28,
    },
  },
}));

interface IKrakenHamburger {
  onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
  "data-id": string;
}

export const MenuHamburger = (props: IKrakenHamburger) => {
  return (
    <StyledBox>
      <IconButton {...props}>
        <IconMenu color="neutralDark" />
      </IconButton>
    </StyledBox>
  );
};
