import {
  IOriginEvents,
  TOPICS,
  eventListener,
} from "@origin-digital/event-dispatcher";
import { Dialog } from "@origin-digital/ods-core";
import React, { useEffect, useState } from "react";
import { Z_INDEX } from "../../consts/style";

const SURVEY_BASE_PATH = "/my/survey";
const DEFAULT_DIALOG_HEIGHT = "65vh";

export const Survey = () => {
  const [showSurvey, setShowSurvey] = useState(false);
  const [surveyType, setSurveyType] = useState("");
  const [dialogHeight, setDialogHeight] = useState(DEFAULT_DIALOG_HEIGHT);
  const [path, setPath] = useState("");
  const queryParams = new URLSearchParams({ surveyType }).toString();

  useEffect(() => {
    const dialogOpenUnsubscribe = eventListener.addListener(
      TOPICS.SURVEY_DIALOG_OPEN,
      (event: IOriginEvents) => {
        if (event.topic === TOPICS.SURVEY_DIALOG_OPEN) {
          setShowSurvey(true);
          setSurveyType(event.payload.surveyType);
          setDialogHeight(event.payload.dialogHeight ?? DEFAULT_DIALOG_HEIGHT);
        }
      }
    );
    return dialogOpenUnsubscribe;
  }, []);

  useEffect(() => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    window.onmessage = (event: any) => {
      if (event?.data?.topic === TOPICS.SURVEY_CLOSE) {
        setShowSurvey(false);
      }
    };
  }, []);

  useEffect(() => {
    if (window) {
      setPath(window.location.origin);
    }
  }, []);

  return (
    /**
     * This dialog has no title or close button, padding is set to be 0
     * All layout is handled by microsite in iframe
     */
    <Dialog
      id="survey-dialog"
      open={showSurvey}
      title=""
      onClose={() => setShowSurvey(false)}
      hideClose
      aria-labelledby="survey-title"
      muiProps={{
        // @ts-ignore TODO: I don't think this has any effect. Next time someone
        // comes here, see if there's an impact if you remove this
        titleProps: { hidden: true },
        contentProps: {
          style: { padding: 0, height: dialogHeight, overflow: "hidden" },
        },
        componentProps: {
          open: showSurvey,
          style: { zIndex: Z_INDEX.SURVEY },
        },
      }}
    >
      <iframe
        src={`${path}${SURVEY_BASE_PATH}?${queryParams}`}
        data-id="survey-iframe"
        id="survey-iframe"
        title="Survey"
        width="100%"
        height="99%"
        frameBorder="0"
      />
    </Dialog>
  );
};
