import { dapiFetchCache } from "@origin-digital/daxi";
import type {
  IProperty,
  IUserContext,
  IAddress,
  IAccount,
  IPropertyWithIds,
} from "@origin-digital/event-dispatcher";

export { IProperty, IUserContext as IUser, IAddress, IAccount };

const storage = (): Storage => window.localStorage;

const storageKey = "@od/selectedProperty";

export const readPropertyFromStorage = (): IPropertyWithIds | null => {
  const json = storage().getItem(storageKey);
  if (json) {
    return JSON.parse(json); // Note this is not validated as a correctly shaped type.
  }
  return null;
};

export const writePropertyToStorage = (property: IPropertyWithIds | null) => {
  try {
    storage().setItem(storageKey, JSON.stringify(property));
  } catch (error) {}
};

export const fetchUserContextV3 = async (): Promise<
  IUserContext | undefined
> => {
  try {
    const res = await dapiFetchCache<IUserContext>("/api/appcontext/v3/user", {
      ttlInMin: 30,
      allowStaleData: true,
      authorization: "jwt",
    });
    return res.data;
  } catch {
    /* Return undefined if unable to fetch user context. Not currently used for critical functionality so can safely silently error
     Handles test cases where this is not configured, and avoids throwing errors for unauthenticated users */
    return undefined;
  }
};
