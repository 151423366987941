import { getOriginCustomerId } from "@origin-digital/auth-utils";
import { dapiFetchCache, xhrFetch } from "@origin-digital/daxi";
import {
  fetchAccessToken,
  ICustomAttribute,
  type IUserContext,
} from "@origin-digital/event-dispatcher";
import { EnvironmentNames } from "@origin-digital/platform-enums";
import { identifier } from "@origin-digital/reporting-client";

import { getUser } from "@braze/web-sdk";
import {
  IBrazeUser,
  MergeBrazeUsers,
  NewBrazeUser,
} from "../consts/interfaces";

import { isCookieYoungerThan, readCookies, writeCookies } from "./cookie";
import {
  deleteLastRefreshDate,
  getMatchingStoredBzid,
  getStorageBzid,
  storeBzid,
} from "./storage";
import { getApiKey } from "./config";

export const BRAZE_API_NEW_USER_ENDPOINT = "/api/braze/users/new";
export const BRAZE_API_MERGE_USER_ENDPOINT = "/api/braze/users/merge";

export const getAccessToken = async (): Promise<string | undefined> => {
  const cachedJWT = await fetchAccessToken({});
  if (!cachedJWT) {
    return undefined;
  }
  return cachedJWT.accessToken;
};

export const fetchUserContextV3 = async (): Promise<
  IUserContext | undefined
> => {
  try {
    const res = await dapiFetchCache<IUserContext>("/api/appcontext/v3/user", {
      ttlInMin: 60,
      allowStaleData: true,
      authorization: "jwt",
    });
    return res.data;
  } catch {
    /* Return undefined if unable to fetch user context. Not currently used for critical functionality so can safely silently error
     Handles test cases where this is not configured, and avoids throwing errors for unauthenticated users */
    return undefined;
  }
};

export const fetchUserServiceBzid = async (): Promise<string | undefined> => {
  const userContext = await fetchUserContextV3();
  const brazeIdObj = userContext?.features.find(({ type }) => type === "BRAZE");
  return brazeIdObj?.backendId;
};

export const fetchJwtBrazeId = async (): Promise<IBrazeUser | undefined> => {
  try {
    const accessToken = await getAccessToken();
    if (!accessToken) return;

    const originId = getOriginCustomerId(accessToken);
    if (!originId) return;

    const matchingStoredBzid = getMatchingStoredBzid(originId);
    if (matchingStoredBzid) return { bzid: matchingStoredBzid, originId };

    const featureBzid = await fetchUserServiceBzid();
    if (featureBzid) return { bzid: featureBzid, originId };
  } catch {}
  return undefined;
};

export const persistUser = (
  brazeObj: IBrazeUser,
  environment: EnvironmentNames
): void => {
  const prevBzid = getStorageBzid().bzid;
  if (prevBzid != null && prevBzid !== brazeObj.bzid) {
    // when user changes ensure we refresh
    deleteLastRefreshDate();
  }
  storeBzid(brazeObj);
  writeCookies(brazeObj, environment);
};

export const syncUserWithAdobe = (brazeObj: IBrazeUser): void => {
  const oneDay = 1000 * 60 * 60 * 24;

  // Sync with Adobe Analytics if:
  // has bzid and cookie is older than a day, or
  // has bzid and has been created
  if (
    brazeObj.bzid &&
    (!isCookieYoungerThan(oneDay, brazeObj.bzid) || brazeObj.created)
  ) {
    identifier("braze", {
      bzid: brazeObj.bzid,
      ...(brazeObj.created && { created: true }),
    });
  }
};

export const getNewBrazeUser = async (
  environment: EnvironmentNames
): Promise<NewBrazeUser> => {
  try {
    const response = await xhrFetch<NewBrazeUser>(BRAZE_API_NEW_USER_ENDPOINT, {
      method: "get",
      headers: {
        "x-api-key": `${getApiKey(environment)}`,
      },
    });
    return response.data || { bzid: undefined, created: false };
  } catch (_) {
    return { bzid: undefined, created: false };
  }
};

export const mergeBrazeUsers = (
  brazeObj: IBrazeUser
): Promise<MergeBrazeUsers> => {
  const curr = brazeObj.bzid ?? "";
  const prev = brazeObj.previousBzid ?? "";

  if (prev === "" || curr === prev) {
    return Promise.resolve({ merged: false });
  }
  return xhrFetch<MergeBrazeUsers>(BRAZE_API_MERGE_USER_ENDPOINT, {
    method: "post",
    params: { prev, curr },
  })
    .then((res) => res.data ?? { merged: false })
    .catch(() => ({ merged: false }));
};

let brazePromise: Promise<IBrazeUser> | undefined;

export const fetchMarketingId = async (
  environment: EnvironmentNames,
  bzidQueryString: string
): Promise<IBrazeUser> => {
  if (brazePromise) return brazePromise;
  //get user data
  // eslint-disable-next-line no-async-promise-executor
  brazePromise = new Promise(async (resolve) => {
    const previousBzid = readCookies()?.bzid ?? getStorageBzid()?.bzid;
    // is auth?
    const jwtBzid = await fetchJwtBrazeId();
    if (jwtBzid?.bzid) return resolve({ ...jwtBzid, previousBzid });

    // has bzid in URL
    if (bzidQueryString)
      return resolve({ bzid: bzidQueryString, previousBzid });

    // check storage
    const cookie = readCookies();
    if (cookie?.bzid) return resolve({ ...cookie, previousBzid });

    const storageBzid = getStorageBzid();
    if (storageBzid.bzid) return resolve({ ...storageBzid, previousBzid });

    // create new user
    const newUser = await getNewBrazeUser(environment);
    if (newUser.bzid) return resolve(newUser);
  });
  const marketingId = await brazePromise;
  // store results
  if (marketingId?.bzid) {
    syncUserWithAdobe(marketingId); // check existing cookies before they are re-written below
    persistUser(marketingId, environment); // storage, cookies, etc...
  }

  return brazePromise;
};

// Used for tests only - resets the brazePromise
export const resetBrazePromise = () => {
  brazePromise = undefined;
};

export const setCustomUserAttribute = ({ key, value }: ICustomAttribute) => {
  getUser()?.setCustomUserAttribute(key, value);
};

export const isNewBrazeUser = () =>
  !readCookies()?.bzid && !getStorageBzid()?.bzid;
